<template>
  <div
    id="special_area"
    :style="{
      '--color': themeColor,
      '--bg-color': themeBgColor,
      '--lg-color': themeLinearGradient,
      '--lg2-color': themeLinearGradient2,
    }"
  >
    <van-sticky class="top_sticky" v-if="record.length">
      <div class="record">
        <span v-for="item in record" :key="item.index">
          {{ item.name
          }}<svg-icon icon-class="del_icon" @click="deleteRecord(item.index)" />
        </span>
      </div>
    </van-sticky>
    <img
      :src="require('@/assets/images/xpsd_banner.png')"
      width="100%"
      v-show="view"
    />
    <img
      :src="require('@/assets/images/czth_banner.png')"
      width="100%"
      v-show="!view"
    />
    <main>
      <van-sticky
        :offset-top="record.length ? 40 : 0"
        class="menu_sticky"
        :style="record.length ? 'top:0.56rem' : 'top:0'"
      >
        <van-dropdown-menu>
          <van-dropdown-item title="品牌" ref="brandItem">
            <template #default>
              <div class="brand_box">
                <div
                  class="img_box"
                  v-for="item in brand"
                  :key="item.id"
                  @click="brandSelect(item)"
                >
                  <img v-lazy="item.logo" />
                </div>
              </div> </template
          ></van-dropdown-item>
          <van-dropdown-item
            title="商品分类"
            @open="classDropdown"
            ref="classItem"
          >
            <template #default>
              <van-tabs line-width="0" :ellipsis="false" v-model="active">
                <van-tab v-for="list in classification" :key="list.categoryId">
                  <template #title>
                    <img :src="list.categoryImage" />
                    <p>{{ list.categoryName }}</p>
                  </template>
                  <template #default
                    ><span
                      @click="classSelect(item, list)"
                      v-for="item in list.subItemList"
                      :key="item.categoryId"
                      :class="item.pitchOn ? 'pitch_on' : ''"
                      >{{ item.categoryName }}</span
                    ></template
                  >
                </van-tab>
              </van-tabs>
            </template>
          </van-dropdown-item>
          <van-dropdown-item title="产地" ref="cityItem">
            <template #default>
              <div class="city">
                <span
                  @click="citySelect(item, index)"
                  v-for="(item, index) in city"
                  :key="index"
                  :class="onCity === index ? 'pitch_on' : ''"
                  >{{ item }}</span
                >
              </div>
            </template></van-dropdown-item
          >
          <van-dropdown-item></van-dropdown-item>
          <svg-icon
            icon-class="screening_default_icon"
            @click="crossbarSwitch = !crossbarSwitch"
            :class="crossbarSwitch ? 'crossbar_pitch_on' : ''"
          />
        </van-dropdown-menu>
        <div class="sort">
          <p
            v-for="item in sort"
            :key="item.i"
            @click="switchover(item.i)"
            :class="index === item.i ? 'pitch_on' : ''"
          >
            {{ item.name }}
            <span v-if="item.i">
              <svg-icon
                icon-class="home_more_icon1"
                :class="index === item.i && order ? 'pitch_on' : ''"
              />
              <svg-icon
                icon-class="home_more_icon1"
                :class="index === item.i && !order ? 'pitch_on' : ''"
              />
            </span>
          </p>
        </div>
        <van-empty
          description="暂无商品"
          v-show="!loading && !goods.length && !error"
        />
      </van-sticky>

      <van-list
        v-model="loading"
        :finished="finished"
        :error.sync="error"
        @load="getGoodsList"
        error-text="请求失败，点击重新加载"
        offset="-10"
      >
        <div class="content" id="goods_list" v-if="goods.length">
          <div v-show="loading">加载中</div>
          <goods
            v-for="(item, index) in goods"
            :key="index"
            :goodsInfo="item"
            :Switch="crossbarSwitch"
          />
        </div>
        <p class="pull_up" v-if="!(error || finished || loading)">
          上拉 查看更多商品 <svg-icon icon-class="home_more_icon" />
        </p>
        <router-link v-show="finished" to="/goodsList" class="end"
          >点击去查看全部商品</router-link
        >
      </van-list>
    </main>
    <functionButton />
  </div>
</template>

<script>
import Goods from "@/components/Goods";
import { mapState } from "vuex";
import { WechatSharing } from "@/utils/sdk";
export default {
  name: "special-area",
  components: { Goods },
  computed: {
    ...mapState("user", ["isLogin", "tzInfo", "userInfo", "change"]),
  },
  data() {
    return {
      themeBgColor: "",
      themeColor: "",
      themeLinearGradient: "",
      themeLinearGradient2: "",
      view: 0,
      active: 0,
      crossbarSwitch: false,
      goods: [],
      pageNum: 1,
      loading: false,
      finished: false,
      error: false,
      index: 0,
      sort: [],
      order: true, //desc asc 升降序
      onTime: 0,
      city: ["北京", "上海", "广东", "福建", "湖南"],
      onCity: "",
      classification: [],
      brand: [],
      brandId: "",
      categoryId: [],
      record: [],
    };
  },
  watch: {
    crossbarSwitch(val) {
      this.$nextTick(function () {
        if (this.goods.length) this.waterFall();
      });
    },
  },
  created() {
    this.viewSwitchover();
    this.getClassification();
    this.getAllBrand();

    // if (this.$route.name === "great-value-special") {
    // } else if (this.$route.name === "new-product-express") {
    //   if (this.$route.query.id) {
    //     // if (this.$route.query.id && !sessionStorage.getItem("userUuid")) {
    //     sessionStorage.setItem("userUuid", this.$route.query.id);
    //     // }
    //   } else {
    //     this.$router.replace({
    //       name: "new-product-express",
    //       query: {
    //         activityId: this.$route.query.activityId,
    //         id: sessionStorage.getItem("userUuid")
    //           ? sessionStorage.getItem("userUuid")
    //           : undefined,
    //         taskId: this.$route.query.taskId,
    //       },
    //     });
    //   }
    //   let data = {
    //     title: "正品汇上新啦",
    //     desc: "买得多，省得多，每日关注新品区，有最新商品推荐，带你尝鲜",
    //     imgUrl: require("@/assets/images/logo.png"),
    //   };
    //   customizeGetJSSDK(`${window.location.origin}/`, data);
    // }
  },
  activated() {
    // this.viewSwitchover();
    if (this.$route.name === "great-value-special") {
    } else if (this.$route.name === "new-product-express") {
      if (this.$route.query.id) {
        // if (this.$route.query.id && !sessionStorage.getItem("userUuid")) {
        sessionStorage.setItem("userUuid", this.$route.query.id);
        // }
      } else {
        this.$router.replace({
          name: "new-product-express",
          query: {
            activityId: this.$route.query.activityId,
            id: sessionStorage.getItem("userUuid")
              ? sessionStorage.getItem("userUuid")
              : undefined,
            taskId: this.$route.query.taskId,
          },
        });
      }

      let shareInfo = {
        title: "正品汇上新啦",
        desc: "买得多，省得多，每日关注新品区，有最新商品推荐，带你尝鲜",
        imgUrl: require("@/assets/images/logo.png"),
        link: window.location.hash,
      };
      WechatSharing(shareInfo);
    }
    if (this.change) this.getGoodsList();
  },
  beforeRouteEnter(to, from, next) {
    // console.log(from);
    if (from.name != "goods-details") {
      next((vm) => {
        vm.active = 0;
        vm.crossbarSwitch = false;
        vm.loading = false;
        vm.error = false;
        vm.index = 0;
        vm.sort = [];
        vm.order = true;
        vm.onTime = 0;
        vm.onCity = "";
        vm.brandId = "";
        vm.categoryId = [];
        vm.record = [];
        vm.refresh = true;
        vm.viewSwitchover();
        vm.getClassification();
        vm.getAllBrand();
        vm.getGoodsList();
        vm.$nextTick(function () {
          vm.waterFall();
        });
        // classification: [],
        // brand: [],
      });
    }

    next();
  },
  methods: {
    viewSwitchover() {
      if (this.$route.name === "great-value-special") {
        this.view = 0;
        this.themeBgColor = "#F75F0E";
        this.themeColor = "#FEC714";
        this.themeLinearGradient = "#F7A21E";
        this.themeLinearGradient2 = "#f78f1a";
        this.sort = [
          { name: "综合排序", i: 0 },
          { name: "销量", i: 1 },
          { name: "价格", i: 2 },
        ];
      } else if (this.$route.name === "new-product-express") {
        this.view = 1;
        this.themeBgColor = "#62c7fe";
        this.themeColor = "#108bff";
        this.themeLinearGradient = " #0a86ff";
        this.themeLinearGradient2 = "#2399ff";

        this.sort = [
          { name: "综合排序", i: 0 },
          { name: "价格", i: 2 },
        ];
      }
    },
    async getGoodsList() {
      if (this.refresh || this.change) {
        this.goods = [];
        this.pageNum = 1;
        this.finished = false;
        this.$store.commit("user/ShoppingCartSumChange", false);
      }
      this.loading = true;
      await this.$API.homePage
        .CommList({
          object: {
            // newProducts: "",
            // deduction: "",
            newProducts: this.view ? true : "",
            deduction: this.view ? "" : 0,
            brandId: this.brandId,
            ceCategoryId: this.categoryId.length ? this.categoryId : null,
            order: this.order ? "desc" : "asc", // desc/asc
            productionPlace: this.city[this.onCity], //产地
            queryCriteria: -this.onTime, //时间排序
            sortColumn: this.sortColumn, // 查询条件
            memberId: this.isLogin ? this.userInfo.id : undefined,
            userId: this.tzInfo ? this.tzInfo.id : "",
          },
          pageNum: this.pageNum,
          pageSize: 6,
        })
        .then((res) => {
          // !this.$route.query.brandId && this.getGoodsBrand();
          if (res.data.code === "UNKNOWN_ERROR") throw Error(res.data.msg);

          res.data.data.forEach((a) => {
            a.filePath = a.filePath.split(",")[0].endsWith(".mp4")
              ? a.filePath.split(",")[1]
              : a.filePath.split(",")[0];
            if (
              a.orderCartListItems.length &&
              !a.orderCartListItems[0].isDelete
            ) {
              a.quantity = a.orderCartListItems[0].quantity;
            } else {
              a.quantity = 0;
            }
          });
          if (this.refresh) {
            this.goods = res.data.data;
          } else {
            res.data.data.forEach((goods) => {
              this.goods.push(goods);
            });
          }

          this.pages = res.data.pages;
          this.pageNum++;
          //版本2
          this.$nextTick(function () {
            if (res.data.data.length) this.waterFall();
          });
          if (this.pageNum > this.pages) {
            this.finished = true;
          }
          this.refresh = false;
          this.loading = false;
        })
        .catch((err) => {
          console.error(err.message);
          this.error = true;
          this.loading = false;
        });
    },
    switchover(i) {
      if (this.index === i && i === 0) return;
      if (this.index != i) this.order = true;
      this.index = i;
      switch (i) {
        case 0:
          this.sortColumn = "";
          break;
        case 1: //销量
          this.order = !this.order;
          break;
        case 2: //价格
          this.order = !this.order;
          this.sortColumn = "retailPrice";
          break;
        default:
          break;
      }
      if (this.index === 1) return;
      //还没销量
      this.refresh = true;
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this.getGoodsList();
      }, 500);
    },
    //获取品牌
    async getAllBrand() {
      let i = this.record.findIndex((item) => {
        return item.index === 0;
      });

      if (i > -1) this.record.splice(i, 1);
      this.brandId = "";

      const data = await this.$API.brand.AllBrand({
        object: {
          brandName: "",
          categoryId: this.categoryId.length ? this.categoryId : null,
          name: "",
        },

        pageNum: 0,
        pageSize: 0,
      });
      this.brand = data.data.data;
    },
    //获取分类
    async getClassification() {
      const data = await this.$API.platform.Classification({
        object: {
          enterpriseId: "",
        },
      });

      this.classification = data.data.data.categoriesCatalog;
      this.classification.forEach((arr) => {
        if (!arr.subItemList) {
          arr.subItemList = [
            {
              categoryName: arr.categoryName,
              categoryId: arr.categoryId,
              rank: 2,
            },
          ];
        }
      });
      const that = this;
      a(this.classification);
      function a(arr) {
        arr.forEach((element, i) => {
          // if (element.rank === 1 && i === 0) {
          //   that.$set(element, "pitchOn", true);
          // } else {
          that.$set(element, "pitchOn", false);
          // }
          if (element.subItemList) {
            a(element.subItemList);
          }
        });
      }
    },

    classSelect(item, parentItem) {
      let categoryId = [];
      let i = this.record.findIndex((item) => {
        return item.index === 1;
      });
      if (item.pitchOn) {
        item.pitchOn = false;
        parentItem.pitchOn = false;
        if (i > -1) {
          this.record.splice(i, 1);
        }
      } else {
        this.classification.forEach((list) => {
          list.pitchOn = false;
          list.subItemList.forEach((item) => {
            item.pitchOn = false;
          });
        });
        item.pitchOn = true;
        parentItem.pitchOn = true;

        getCategoryId(item);

        function getCategoryId(item) {
          categoryId.push(item.categoryId);
          if (item.subItemList && item.subItemList.length) {
            item.subItemList.forEach((item2) => {
              getCategoryId(item2);
            });
          }
        }

        if (i > -1) {
          this.record.splice(i, 1, {
            name: item.categoryName,
            index: 1,
          });
        } else {
          this.record.push({
            name: item.categoryName,
            index: 1,
          });
        }
      }

      this.refresh = true;
      this.categoryId = categoryId;
      this.getAllBrand();
      this.getGoodsList();
      this.$refs.classItem.toggle();
    },
    citySelect(city, index) {
      let i = this.record.findIndex((item) => {
        return item.index === 2;
      });
      if (this.onCity !== index) {
        this.onCity = index;
        if (i > -1) {
          this.record.splice(i, 1, {
            name: city,
            index: 2,
          });
        } else {
          this.record.push({
            name: city,
            index: 2,
          });
        }
      } else {
        this.record.splice(i, 1);
        this.onCity = "";
      }

      this.refresh = true;
      this.getGoodsList();
      this.$refs.cityItem.toggle();
    },
    brandSelect(item) {
      if (item.id === this.brandId) {
        this.$refs.brandItem.toggle();
        return;
      }
      this.brandId = item.id;
      let i = this.record.findIndex((item) => {
        return item.index === 0;
      });

      if (i > -1) {
        this.record.splice(i, 1, {
          name: item.brandName,
          index: 0,
        });
      } else {
        this.record.push({
          name: item.brandName,
          index: 0,
        });
      }

      this.refresh = true;
      this.getGoodsList();
      this.$refs.brandItem.toggle();
      // console.log(item.id);
      // console.log(this.brandId);
    },
    classDropdown() {
      let i = this.classification.findIndex((item) => {
        return item.pitchOn;
      });
      if (i > -1) this.active = i;
    },

    deleteRecord(index) {
      let i = this.record.findIndex((item) => {
        return item.index === index;
      });
      this.record.splice(i, 1);

      if (index === 0) {
        this.getAllBrand();
      } else if (index === 1) {
        this.categoryId = [];
        this.classification.forEach((list) => {
          list.pitchOn = false;
          list.subItemList.forEach((item) => {
            item.pitchOn = false;
          });
        });
        this.getAllBrand();
      } else if (index === 2) {
        this.onCity = "";
      }

      this.refresh = true;
      this.getGoodsList();
    },
    waterFall() {
      let container = document.getElementById("goods_list");
      let item = document.getElementsByClassName("goods_item_box");
      if (this.crossbarSwitch || !item.length) {
        if (container) container.style.height = `auto`;
        return;
      }
      // 1. 设置container盒子的宽度
      //      注意：浏览器的可视区域的宽度 / 一个item元素的宽度 = 一行的排列的元素的个数

      //获取元素的宽度(含border，padding)
      let width = item[0].offsetWidth;
      //计算出浏览器窗口的宽度
      let clientWidth = document.documentElement.clientWidth;
      //计算出应该放几列（向下取整）
      // let columnCount = Math.floor(clientWidth / width);
      let columnCount = 2;
      //设置容器（父盒子）的宽度
      // container.style.width = columnCount * width + "px";
      let left = container.offsetWidth - width * columnCount; //宽度间隔
      let height = 12; //高度间隔

      // 2.设置每一个item元素的排列位置
      //  第一行整体的top值都是0 后面的依次找上一行高度最小的容器，在它下面进行排列
      let hrr = [];
      for (let i = 0; i < item.length; i++) {
        //定位第一行的图片
        if (i < columnCount) {
          item[i].style.top = "0px";
          if (i) {
            item[i].style.left = i * width + left + "px";
          } else {
            item[i].style.left = i * width + "px";
          }
          hrr.push(item[i].offsetHeight + height);
        } else {
          //第一行之后的
          //选择总高度最小的列
          let min = Math.min(...hrr);
          let index = hrr.indexOf(min);
          //将每个元素定位到当前总高度最小的列下
          //index为0在左边 为1在右边 2以此类推
          item[i].style.top = min + "px";
          if (index) {
            item[i].style.left = index * width + left + "px";
          } else {
            item[i].style.left = index * width + "px";
          }
          //当前定位的元素加入该列
          hrr[index] += item[i].offsetHeight + height;
        }
      }

      if (hrr.length < 2) {
        container.style.height = `${hrr[0]}px`;
        return;
      }
      if (hrr[0] >= hrr[1]) {
        container.style.height = `${hrr[0]}px`;
      } else {
        container.style.height = `${hrr[1]}px`;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#special_area {
  display: flex;
  flex-direction: column;
  height: 100%;
  .top_sticky {
    z-index: 1000;
    ::v-deep .van-sticky {
      padding: 7px 15px;
      max-width: 500px;
      margin: 0 auto;
      background: linear-gradient(
        180deg,
        var(--lg-color) 0%,
        var(--lg2-color) 100%
      );
      position: absolute;
      top: 0;
      left: 0;
      right: 0;

      .record {
        height: 32px;
        background: #fff;
        border-radius: 16px;
        padding: 0 16px;
        display: flex;
        align-items: center;
        overflow-x: scroll;
        span {
          color: #666666;
          margin-right: 8px;
          flex-shrink: 0;
          .svg-icon {
            margin-left: 6px;
            font-size: 15px;
          }
        }
      }
      .record::-webkit-scrollbar {
        display: none;
      }
    }
    ::v-deep .van-sticky--fixed {
      position: fixed;
    }
  }
  main {
    flex: 1;
    // padding: 15px;
    background: var(--bg-color);
    ::v-deep .menu_sticky {
      .van-sticky {
        padding: 15px;
      }
      .van-sticky--fixed {
        padding: 15px;
        max-width: 500px;
        margin: 0 auto;
        background: linear-gradient(
          180deg,
          var(--lg2-color) 0%,
          var(--bg-color) 100%
        );
      }
    }
    ::v-deep .van-dropdown-menu {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      .van-dropdown-menu__bar {
        flex: 1;
        height: 32px;
        justify-content: space-between;
        background: transparent;
        box-shadow: none;
        .van-dropdown-menu__item {
          flex: none;
          border-radius: 16px;
          background: #fff;
          padding: 0 16px;
          .van-dropdown-menu__title {
            padding: 0 8px 0 0;
            .van-ellipsis {
              color: var(--bg-color);
              font-size: 14px;
            }
          }
          .van-dropdown-menu__title::after {
            border-color: transparent transparent var(--bg-color)
              var(--bg-color);
          }
        }
        .van-dropdown-menu__item:last-child {
          width: 0;
          padding: 0;
          overflow: hidden;
        }
      }
      .van-dropdown-item {
        margin-top: 8px;
        .van-overlay {
          background-color: rgba(0, 0, 0, 0.3);
        }
        .van-popup {
          padding: 20px 15px 14px;
          border-radius: 0px 0px 20px 20px;
          max-height: 80%;
          max-width: 500px;
          margin: 0 auto;
          right: 0;
          .brand_box {
            display: flex;
            flex-wrap: wrap;
            .img_box {
              display: inline-block;
              position: relative;
              width: 16.5%;
              height: 0;
              padding-top: 16.5%;
              margin-right: calc(17.5% / 4);
              margin-bottom: 16px;
              img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border-radius: 50%;
                object-fit: contain;
              }
            }
            .img_box:nth-child(5n) {
              margin-right: 0;
            }
          }
          .van-tabs__wrap {
            height: auto;
            .van-tabs__nav {
              padding: 0;
              .van-tab {
                padding: 0;
                margin-right: 12px;
                .van-tab__text {
                  text-align: center;
                  img {
                    width: 50px;
                    height: 50px;
                    border: 2px solid transparent;
                    border-radius: 50%;
                    object-fit: cover;
                    transition: 0.2s all;
                  }
                  p {
                    height: 24px;
                    line-height: 24px;
                    padding: 0 10px;
                    border-radius: 12px;
                    color: #333333;
                    transition: 0.2s all;
                  }
                }
              }
              .van-tab:last-child {
                margin: 0;
              }
              .van-tab--active {
                img {
                  border: 2px solid var(--bg-color) !important;
                }
                p {
                  background: var(--bg-color);
                  color: #fff !important;
                }
              }
            }
          }
          .van-tabs__content {
            padding-top: 30px;
            .van-tab__pane {
              span {
                display: inline-block;
                width: 23%;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                padding: 0 6px;
                margin-right: calc(8% / 3);
                height: 32px;
                line-height: 32px;
                text-align: center;
                background: #f5f6fa;
                color: #666666;
                border-radius: 16px;
                margin-bottom: 16px;
                transition: 0.2s all;
              }
              span:nth-child(4n) {
                margin-right: 0;
              }
              .pitch_on {
                background: var(--bg-color);
                color: #fff;
              }
            }
          }
          .city {
            span {
              display: inline-block;
              width: 23%;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              padding: 0 6px;
              margin-right: calc(8% / 3);
              height: 32px;
              line-height: 32px;
              text-align: center;
              background: #f5f6fa;
              color: #666666;
              border-radius: 16px;
              margin-bottom: 16px;
              transition: 0.2s all;
            }
            span:nth-child(4n) {
              margin-right: 0;
            }
            .pitch_on {
              background: var(--bg-color);
              color: #fff;
            }
          }
        }
      }

      .svg-icon {
        color: #fff !important;
        font-size: 22px;
      }
      .crossbar_pitch_on {
        color: var(--color) !important;
      }
    }
    .sort {
      display: flex;
      align-items: center;
      p {
        display: flex;
        color: #fff;
        margin-right: 46px;
        span {
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-left: 4px;
          .svg-icon {
            font-size: 7px;
            font-weight: bold;
            color: #fff;
          }
          .svg-icon:nth-child(1) {
            transform: rotate(-90deg);
          }
          .svg-icon:nth-child(2) {
            transform: rotate(90deg);
          }
          .pitch_on {
            color: var(--color);
          }
        }
      }
      .pitch_on {
        color: var(--color);
      }
    }

    .content {
      position: relative;
      width: 100%;
    }
    ::v-deep .van-list {
      padding: 0 15px 15px;

      .van-loading {
        color: #fff;
      }
      .van-loading__text {
        color: #fff;
      }
      .van-list__finished-text {
        color: #fff;
      }
      .van-list__error-text {
        color: #fff;
      }
    }
    ::v-deep .van-empty {
      padding-bottom: 80px;
      .van-empty__description {
        color: #fff;
      }
    }
    .pull_up {
      display: flex;
      // padding-top: 12px;
      font-size: 12px;
      color: #fff;
      justify-content: center;
      align-items: center;
      .svg-icon {
        color: #fff;
        font-size: 8px;
        margin-left: 4px;
        transform: rotate(90deg);
      }
    }
    .end {
      display: block;
      text-align: center;
      line-height: 24px;
      color: #fff;
    }
  }
}
</style>
